<template>
    <v-col class="px-0 py-0 mb-5">
        <div :style="fileInputStyle" @click="triggerFileInput">
            <v-img
                v-if="previewUrl && !uploading"
                :src="previewUrl"
                max-width="100%"
                max-height="100%"
                class="mt-0 image-preview"
            ></v-img>
            <div v-else-if="uploading" class="uploading-container">
                <v-progress-linear
                    :value="uploadProgress"
                    color="primary"
                    height="15"
					striped
                    class="progress-bar mb-4"
                ></v-progress-linear>
                <div class="uploading-text">{{ $t("imageUpload.Uploadingfile") }}</div>
            </div>
            <v-icon v-else class="icon-placeholder">
                mdi-image-edit-outline
            </v-icon>
        </div>
        <v-file-input
            ref="fileInput"
            v-model="file"
            accept="image/jpeg,image/png,image/jpg,image/gif,image/bmp,image/webp,image/tiff,image/x-icon"
            show-size
            :label="$t('imageUpload.Uploadfile')"
            prepend-icon="mdi-image-edit-outline"
            @change="previewImage"
            style="display: none"
        ></v-file-input>
        <v-dialog v-model="cropperDialog"  persistent :max-width="computedMaxWidth">
          <v-card>
            <v-alert :value="true" class="alert">
              <v-row class="mx-1" no-gutters>
                <v-col cols="12" class="px-10 py-4 d-flex justify-start ">
                  <p class="title-question p-0">{{ $t('harmony.cut-image') }}</p>
                </v-col>
                <v-col cols="12" class="py-0 d-flex justify-center pb-6 container-cropper">
                  <vue-cropper
                    v-if="cropperDialog"
                    ref="cropper"
                    class="cropper"
                    :src="imgSrc"
                    :guides="true"
                    :background="false"
                    :drag-mode="'move'"
                    :view-mode="1"
                    :autoCropArea="1"
                    :responsive="true"
                    :minCropBoxWidth="100"
                    :minCropBoxHeight="100"
                    style="width: 100%; height: 400px"
                  />
                </v-col>
              </v-row>

              <v-card-actions class="mx-10 mb-6 card-actions">
                    <v-btn class="buttons-card float-right white--text text-capitalize p-12" color="#4A5568" small  @click="savedImage()">Guardar
                    </v-btn>
                </v-card-actions>

            </v-alert>
          </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import axios from '@/axios.js';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    name: 'ImageUpload',
    components: {
        VueCropper
    },
    props: {
        selected: {
            type: String,
            required: false,
            default: ''
        },
        uploadEndpoint: {
            type: String,
            required: true,
        },
        cardSelected: {
            type: String,
            required: false,
            default: ''
        },
        siteId: {
            type: Number,
            required: false,
            default: 0
        },
        previewUrl: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            file: null,
            imagePreview: null,
            uploading: false,
            uploadProgress: 0,
            cropperDialog: false,
            imgSrc: null,
            loading: false
        };
    },

    computed: {
        fileInputStyle() {
            return {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                border: '2px solid #C9C9C9',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
            };
        },
        computedMaxWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '90%' : '900px';
    }
    },

    created() {
        this.setLanguage()
    },

    methods: {
        triggerFileInput() {
            this.$refs.fileInput.$el.querySelector('input').click();
        },

        previewImage(file) {
            const MAX_SIZE = 30 * 1024 * 1024; // 30MB

            const ALLOWED_TYPES = [ //Imagenes permitidas
                'image/jpeg', 'image/png', 'image/jpg', 'image/gif',
                'image/bmp', 'image/webp', 'image/tiff', 'image/x-icon'
            ];

            if (!ALLOWED_TYPES.includes(file.type)) {
                this.$vs.notify({
                    title: this.$t('imageUpload.typeError-title'),
                    text: this.$t('imageUpload.typeError-text'),
                    color: 'warning',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                return;
            }

            if (file.size > MAX_SIZE) {
                this.$vs.notify({
                    title: this.$t('imageUpload.sizeError-title'),
                    text: this.$t('imageUpload.sizeError-text', { maxSize: '30MB' }),
                    color: 'warning',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                return;
            }

            if(file) {
                this.openCropper(file);
            }
        },


        openCropper(file){
            if (file) {
                this.imgSrc = URL.createObjectURL(file);
                this.cropperDialog = true;
            }
        },


        savedImage() {
            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                this.imagePreview = URL.createObjectURL(blob);
                this.uploadImage(blob);
                this.cropperDialog = false;
                this.imgSrc = null;
            });
        },

        async uploadImage(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('card', this.cardSelected);
            formData.append('site_id', this.siteId);

            this.uploading = true;
            this.uploadProgress = 0;

            try {
                const response = await axios.post(this.uploadEndpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                });

                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;

                if (response && this.selected == 'logo') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadLogo-title'),
                        text: this.$t('imageUpload.uploadLogo-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else if (response && this.selected == 'card') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadCard-title'),
                        text: this.$t('imageUpload.uploadCard-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else if (response && this.selected == 'background') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadBackground-title'),
                        text: this.$t('imageUpload.uploadBackground-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
                this.$emit('image-uploaded');
            } catch (error) {
                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t('imageUpload.uploadError-title'),
                    text: this.$t('imageUpload.uploadError-text'),
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error('Upload Error:', error);
            }
        },

        setLanguage(){
            const storedLocale = localStorage.getItem('locale');
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = 'es'
            }
        },
    },
};
</script>

<style scoped>
.v-file-input .v-input__control {
    display: none;
}
.v-file-input .v-label {
    width: 100%;
    text-align: center;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-placeholder {
    font-size: 40px;
    color: #C9C9C9;
}

.uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.uploading-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #8e84c0;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
}

.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}
.title-question {
    font-size: 18px !important;
    color: #4A5568;
    font-weight: 800;
    margin-top: 16px !important;
}
.buttons-card {
    padding-left: 32px !important;
    padding-right: 32px !important;
}
.card-actions {
float: right;
}

@media (max-width: 600px) {
    .card-actions {
        padding-bottom: 12px !important;
        display: flex;
        justify-content: space-around; 
        align-items: center;
        margin-right: 0px !important;
        margin-left: 0px !important;
        float: none;
    }

    .buttons-card {
        padding-left: 16px !important;
        padding-right: 16px !important;
        margin-bottom: 10px; /* Añade un margen inferior para separarlos */
          text-align: center;
    }

    .title-question {
        text-align: center; /* Centra el texto de 'Recortar Imagen' */
        width: 100%;
    }
}



</style>


<style lang="scss">

.alert {
    margin: 0 !important;
    padding: 0 !important;
}

.cropper {
    max-width: 90%;
}

</style>
