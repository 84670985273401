
   <template>
  <v-dialog v-model="localIsOpen" max-width="500px" transition="dialog-transition" content-class="elevation-0">
    <video
      v-if="mediaType === 'video'"
      :src="mediaSrc"
      :controls="false"
      :autoplay="true"
      :loop="true"
      muted
      :preload="'auto'"
      :playsinline="true"
      class="w-full transition-ease cursor-pointer"
      @click="close"
    ></video>
    <img
      v-else
      :src="mediaSrc"
      class="transition-ease cursor-pointer"
      @click="close"
      :alt="'Image'"
    />
  </v-dialog>
</template>



<script>
export default {
  props: {
    mediaSrc: {
      type: String,
      required: true
    },
    mediaType: {
      type: String,
      default: 'video', // default to 'image' if not specified
      validator: value => ['image', 'video'].includes(value)
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localIsOpen: this.isOpen
    };
  },
  watch: {
    isOpen(newVal) {
      this.localIsOpen = newVal;
    },
    localIsOpen(newVal) {
      this.$emit('update:isOpen', newVal);
    }
  },
  methods: {
    close() {
      this.localIsOpen = false;
    }
  }
};
</script>
<style scoped>
.transition-ease {
    transition: all 0.3s ease-in-out;
    border-radius: 2rem !important;
}
</style>
