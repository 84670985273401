<template>
    <v-col class="px-0 py-0">
        <v-dialog v-model="localShowSchedule" @click:outside="closeDialog" @hide-edit="editSchedule" max-width="350px"
            min-width="340px" style="min-width: 340px !important;">
            <v-card class="mx-auto text-left elevation-0 pa-8 rounded-xl" max-width="400" tile
                v-if="sortedDates.length > 0">
                <v-list class="py-0">
                    <v-list-item v-for="(event, index) in sortedDates" :key="index" class="pa-0 d-flex">
                        <v-list-item-content class="py-0 list-events position-relative">
                            <v-list-item-title class="pt-3">{{ getDayName(event.name_day) }}
                                <span v-if="event.isOnlive" class="nextSchedule">{{
                                    $t('scheduleComponentCard.live') }}</span>
                                <span v-if="todayDay === event.name_day && event.expired && !event.isOnlive"
                                    class="nextSchedule">{{ $t('scheduleComponentCard.today') }}</span>
                                <span v-if="nextSchedule == event.name_day && todayDay != event.name_day"
                                    class="nextSchedule"> {{ $t('scheduleComponentCard.next') }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('harmony.start-time') }} {{ formatTime(event.start_time) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                {{ $t('harmony.end-time') }} {{ formatTime(event.end_time) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="event.url" class="pb-2">
                                <a :href="event.url" target="_blank">{{ $t('harmony.link') }}</a>
                            </v-list-item-subtitle>

                            <!-- deleteSchedule -->
                            <div class="button-delete">
                                <v-btn text color="#8e84c0" class="text-black rounded-md py-1" small
                                    :loading="addloading" @click="deleteSchedule(event.id)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>

                                <v-btn text color="#8e84c0" class="text-black rounded-md py-1" small
                                    :loading="addloading" @click="goToEditSchedule(event)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </div>

                            <div v-if="!event.url" class="mb-3"></div>
                            <hr class="w-100" v-if="index < sortedDates.length - 1" />

                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <p class="text-left menu-text mb-0">
            {{ $t("harmony.add-schedule") }}
        </p>
        <div class="d-flex justify-center scope-margin">
            <v-select v-model="name_day" ref="filteredDayOptions" :items="filteredDayOptions" item-value="id"
                item-text="name" outlined dense color="#9BA9BD" :rules="[rules.required]"
                :placeholder="$t('harmony.select-day')" class="mt-1 mb-3 scope-margin" @input="checkInputs" />
        </div>

        <v-row class="scope-margin">

            <v-col class="col-12 py-2 d-flex flex-column ">
                <v-container class="pa-0 mt-0 relative  mb-2" @click="resetTime('start')">
                    <vue-timepicker ref="selectedStartHour" class="timer" v-model="selectedStartHour" :format="format"
                        manual-input :placeholder="$t('harmony.start-time')" :rules="[rules.required]"
                        @input="checkInputs" />
                    <v-icon size="16px" color=" black" class="icon top-0">
                        mdi-clock-time-eight-outline
                    </v-icon>
                </v-container>
            </v-col>

            <v-col class="col-12 pb-2 mb-4 d-flex flex-column ">
                <v-container class="pa-0 mt-0 relative  mb-2" @click="resetTime('end')">
                    <vue-timepicker ref="selectedEndHour" class="timer" v-model="selectedEndHour" :format="format"
                        manual-input :placeholder="$t('harmony.end-time')" :rules="[rules.required]"
                        @input="checkInputs" />
                    <v-icon size="16px" color=" black" class="icon top-0">
                        mdi-clock-time-eight-outline
                    </v-icon>
                </v-container>
            </v-col>


        </v-row>
    </v-col>

</template>
<script>
import axios from "@/axios.js";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
    components: {
        VueTimepicker
    },
    name: "ScheduleUpload",
    props: {
        uploadEndpoint: {
            type: String,
            required: true,
        },
        cardSelected: {
            type: String,
            required: false,
            default: "",
        },
        siteId: {
            type: Number,
            required: false,
            default: 0,
        },
        schedule: {
            type: Array,
            required: true
        },
        showSchedule: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            file: null,
            imagePreview: null,
            uploadProgress: 0,
            todayDay: this.setToday(),
            nextSchedule: this.setNextSchedule(),
            addloading: false,
            onLive: false,
            // showEdit: false,
            rules: {
                required: value => !!value || this.$t('navbar.required'),
                url: value => {
                    if (!value) return true;
                    const urlRegex = RegExp(/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,4}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/gi);
                    return urlRegex.test(value) || this.$t('harmony.invalid-url');
                },
                endTime: value => {
                    const startTime = this.selectedStartHour;
                    if (!value || !startTime) return true;
                    const start = new Date(`1970-01-01T${startTime}:00`);
                    const end = new Date(`1970-01-01T${value}:00`);
                    if (start >= end) {
                        return this.$t('harmony.invalid-end-time');
                    }

                    return true;
                },
            },
            format: "hh:mm a",
            selectedStartHour: {},
            selectedEndHour: {},
            urlSchedule: null,
            name_day: null,
            localShowSchedule: this.showSchedule,
            currentSchedule: null,

        };
    },
    computed: {
        sortedDates() {
            this.setToday()
            this.setNextSchedule()
            return this.schedule
                .map(event => ({
                    id: event.id || "",
                    start_time: event.start_time || "",
                    end_time: event.end_time || "",
                    name_day: event.name_day || "",
                    url: this.urlFormat(event.url),
                    isOnlive: this.setOnlive(event.start_time, event.end_time, event.name_day).onlive,
                    expired: this.setOnlive(event.start_time, event.end_time, event.name_day).expired,
                }))
                .sort((a, b) => a.name_day - b.name_day);
        },
        filteredDayOptions() {
            let occupiedDays = this.schedule.map(item => item.name_day);
            const filteredOptions = this.dayOptions.filter(day => {
                return !occupiedDays.includes(String(day.id));
            });
            if (this.currentSchedule && this.currentSchedule.name_day) {
                const currentDayExists = filteredOptions.some(day => day.id === this.currentSchedule.name_day);

                if (!currentDayExists) {

                    const currentDay = this.dayOptions.find(day => day.id === parseInt(this.currentSchedule.name_day));

                    if (currentDay) {
                        filteredOptions.push(currentDay);
                    }
                }
            }
            return filteredOptions;
        },
        isFormValid() {
            if (this.rules.required(this.name_day) === true &&
                this.rules.required(this.selectedStartHour) === true &&
                this.rules.required(this.selectedEndHour) === true &&
                this.rules.endTime(this.selectedEndHour) === true) {
                this
                return true
            }
            return false
        },
    },
    created() {
        this.setLanguage();
        this.setDayOptions()
    },
    watch: {
        showSchedule(newVal) {
            this.localShowSchedule = newVal;
        }
    },
    mounted(){
        this.moveTwelveToEndInAllLists();
    },
    methods: {
        resetTime(start) {
            if (start == 'start') {
                if (!this.selectedStartHour.a || !this.selectedStartHour.hh || !this.selectedStartHour.mm)
                    this.selectedStartHour = {
                        hh: '01',
                        mm: '00',
                        a: 'am'
                    }
            } if (start == 'end') {
                if (!this.selectedEndHour.a || !this.selectedEndHour.hh || !this.selectedEndHour.mm) {
                    this.selectedEndHour = {
                        hh: '01',
                        mm: '00',
                        a: 'am'
                    }
                }
            }
            this.moveTwelveToEndInAllLists()
        },
        closeDialog() {
            this.$emit('close-dialog');
        },
        updatedSchedule() {
            this.$emit('updated-schedule');
        },
        onAddScheduleClosed() {
            this.name_day = '';
            this.urlSchedule = '';
            this.selectedStartHour = {
                hh: '',
                mm: '',
                a: ''
            };
            this.selectedEndHour = {
                hh: '',
                mm: '',
                a: ''
            };
            this.$refs.filteredDayOptions.resetValidation();
        },
        setDayOptions() {
            this.dayOptions = [{ id: 1, name: this.$t('harmony.day-1') },
            { id: 2, name: this.$t('harmony.day-2') },
            { id: 3, name: this.$t('harmony.day-3') },
            { id: 4, name: this.$t('harmony.day-4') },
            { id: 5, name: this.$t('harmony.day-5') },
            { id: 6, name: this.$t('harmony.day-6') },
            { id: 7, name: this.$t('harmony.day-7') }]
        },
        isEmpty() {
            let status
            if (this.schedule) {
                status = true
            }
            else {
                status = false
            }
            return status
        },
        formatTime(time) {
            if (!time) return 'No Time Available';
            return time.slice(0, 5);
        },
        getDayName(name_day) {
            const dayKeys = [
                'harmony.day-1',
                'harmony.day-2',
                'harmony.day-3',
                'harmony.day-4',
                'harmony.day-5',
                'harmony.day-6',
                'harmony.day-7'
            ];
            return this.$t(dayKeys[name_day - 1]) || "Dia invalido";
        },
        async uploadSchedule(newDetail) {
            this.$emit('updated-settings');
            const formData = new FormData();
            formData.append("card", this.cardSelected);
            formData.append("site_id", this.siteId);
            formData.append('name_day', newDetail.name_day);
            formData.append('start_time', newDetail.start_time);
            formData.append('end_time', newDetail.end_time);
            formData.append('url', newDetail.url || '');


            this.addloading = true;
            this.uploadProgress = 0;

            try {
                const response = await axios.post(this.uploadEndpoint, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });

                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;

                if (response) {
                    this.$vs.notify({
                        title: this.$t("imageUpload.uploadCard-title"),
                        text: this.$t("imageUpload.uploadCard-text"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
            } catch (error) {
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t("imageUpload.uploadError-title"),
                    text: this.$t("imageUpload.uploadError-text"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Upload Error:", error);
            }
            this.setNextSchedule()
            this.setToday()
            this.addloading = false
        },
        async deleteSchedule(id) {
            if (this.schedule.length == 1) {
                this.$root.$emit('deleteSchedule');
                this.closeDialog();
                return
            }
            const formData = new FormData();
            formData.append("id", id);
            formData.append("site_id", this.siteId);

            this.addloading = true;

            try {
                const response = await axios.post('/remove-schedule', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });
                if (response) {
                    this.$vs.notify({
                        title: this.$t("imageUpload.uploadCard-title"),
                        text: this.$t("imageUpload.uploadCard-text"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
                this.updatedSchedule();
            } catch (error) {
                this.addloading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t("imageUpload.uploadError-title"),
                    text: this.$t("imageUpload.uploadError-text"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Upload Error:", error);
            }
            this.setNextSchedule();
            this.setToday();
            this.addloading = false;
        },
        goToEditSchedule(schedule) {
            this.currentSchedule = schedule;
            this.selectedStartHour = this.setToEdit(schedule.start_time.slice(0, -3));
            this.selectedEndHour = this.setToEdit(schedule.end_time.slice(0, -3));
            this.closeDialog();
            this.showEdit();
            this.name_day = parseInt(schedule.name_day);
        },

        async editSchedule() {
            let startTime = this.setTime(this.selectedStartHour) + ':00'
            let endTime = this.setTime(this.selectedEndHour) + ':00'

            if (startTime.replace(':', '') > endTime.replace(':', '')) {
                startTime = this.setTime(this.selectedEndHour) + ':00'
                endTime = this.setTime(this.selectedStartHour) + ':00'
            }

            this.$emit('updated-settings');
            const formData = new FormData();
            formData.append("id", this.currentSchedule.id);
            formData.append("site_id", this.siteId);
            formData.append("name_day", this.name_day);
            formData.append("start_time", startTime);
            formData.append("end_time", endTime);

            this.currentSchedule = null;
            // this.showEdit = false;
            try {
                const response = await axios.post('/edit-schedule', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });
                if (response) {
                    this.$vs.notify({
                        title: this.$t("scheduleComponentCard.editSuccessTitle"),
                        text: this.$t("scheduleComponentCard.editSuccessText"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
                this.updatedSchedule();
            } catch (error) {
                this.uploadProgress = 0;
                this.$vs.notify({
                    title: this.$t("scheduleComponentCard.editErrorTitle"),
                    text: this.$t("scheduleComponentCard.editErrorText"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Edit Error:", error);
            }
            this.setNextSchedule();
            this.setToday();
            this.onAddScheduleClosed();
        },
        setLanguage() {
            const storedLocale = localStorage.getItem("locale");
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = "es";
            }
        },
        setToday() {
            const today = (new Date().getDay() + 1).toString();
            return today;
        },
        setNextSchedule() {
            if (!Array.isArray(this.schedule) || this.schedule.length === 0) {
                this.nextSchedule = null;
                return;
            }
            const now = new Date();
            const todayDay = now.getDay() + 1;
            let nearestDayDifference = Infinity;

            this.schedule.forEach(event => {
                const { start_time, end_time } = event;
                const name_day = parseInt(event.name_day);

                // Convertir el formato "HH:MM" en horas y minutos
                const [startHour, startMinute] = start_time.split(':').map(Number);
                const [endHour, endMinute] = end_time.split(':').map(Number);

                const eventStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
                const eventEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

                let dayDifference = name_day - todayDay;
                if (dayDifference < 0) {
                    dayDifference += 7;
                }

                eventStart.setDate(now.getDate() + dayDifference);
                eventEnd.setDate(now.getDate() + dayDifference);

                // Revisar si el evento ya pasó o es el próximo
                if (eventStart > now && name_day != todayDay || eventEnd > now && name_day != todayDay) {
                    if (dayDifference < nearestDayDifference) {
                        nearestDayDifference = dayDifference;
                    }
                }


                if (eventStart < now && eventEnd > now && this.onLive) {
                    this.onLive = true
                }

            });
            const remaining = (nearestDayDifference !== Infinity ? nearestDayDifference : 7)
            if ((remaining + todayDay) > 7) {
                return this.nextSchedule = (remaining - (7 - todayDay));
            } else {
                return this.nextSchedule = (remaining + todayDay);
            }
        },
        urlFormat(url) {
            if (url) {
                return url.startsWith('http://') || url.startsWith('https://')
                    ? url
                    : 'https://' + url;
            }
            return url
        },
        setOnlive(start_time, end_time, name_day) {
            let active = false;
            let onlive = false;
            const now = new Date();
            const todayDay = now.getDay() + 1;

            // Convertir el formato "HH:MM" en horas y minutos
            const [startHour, startMinute] = start_time.split(':').map(Number);
            const [endHour, endMinute] = end_time.split(':').map(Number);

            const eventStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
            const eventEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

            let dayDifference = name_day - todayDay;
            if (dayDifference < 0) {
                dayDifference += 7;
            }

            eventStart.setDate(now.getDate() + dayDifference);
            eventEnd.setDate(now.getDate() + dayDifference);
            if (eventStart < now && eventEnd > now) {
                onlive = true
            }
            if (eventEnd > now && name_day == todayDay) {
                active = true
            }

            const result = {
                expired: active,
                onlive: onlive,
            }

            return result
        },
        saveSchedule() {
            this.addloading = true
            let startHour = this.setTime(this.selectedStartHour)
            let endHour = this.setTime(this.selectedEndHour)
            if (startHour.replace(':', '') > endHour.replace(':', '')) {
                startHour = this.setTime(this.selectedEndHour)
                endHour = this.setTime(this.selectedStartHour)
            }
            const newDetail = {
                name_day: this.name_day,
                start_time: startHour + ':00',
                end_time: endHour + ':00'
            };
            this.uploadSchedule(newDetail);
            this.onAddScheduleClosed();
            this.closeDialog();
            this.updatedSchedule()
        },
        showEdit() {
            this.$emit('show-edit');
        },
        checkInputs() {
            if (this.name_day || (this.selectedStartHour.hh && this.selectedStartHour.mm && this.selectedStartHour.a) || (this.selectedEndHour.hh && this.selectedEndHour.mm && this.selectedEndHour.a)) {
                this.$emit('show-addSchedule', {
                    showAddSchedule: true,
                    enable_or_disabled: this.isFormValid,
                });
            }
        },
        setTime(obj) {
            let time
            let hour = obj.hh
            if (obj.a == 'pm') {
                if (hour != '12') {
                    hour = parseInt(obj.hh) + 12
                }
            } else {
                if (hour == '12') {
                    hour = '00'
                }
            }
            time = hour + ':' + obj.mm
            return time
        },
        setToEdit(obj) {
            let time = {
                hh: '',
                mm: '',
                a: ''
            }
            let hour = parseInt(obj.slice(0, 2));
            if (hour >= 12) {
                time.a = 'pm';
                if (hour > 12) {
                    time.hh = (hour - 12).toString();
                    if (time.hh.length <= 1) {
                        time.hh = '0' + (hour - 12).toString();
                    }
                } else {
                    time.hh = '12';
                }
            } else {
                time.hh = '0' + hour.toString();
                if (time.hh.length == 3) {
                    time.hh = hour.toString();
                }
                time.a = 'am';
                if (hour === 0) {
                    time.hh = '12';
                }
            }
            time.mm = obj.slice(3, 5);
            return time;
        },
        moveTwelveToEndInAllLists() {
            const hoursLists = this.$el.querySelectorAll('.hours');

            hoursLists.forEach(hoursList => {
                const twelveHour = hoursList.querySelector('li[data-key="12"]');
                if (twelveHour) {
                    hoursList.appendChild(twelveHour);
                }
            });
        }
    },
};
</script>

<style scoped>
.scope-margin .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0% !important;
}

.menu-text {
    font-size: 14px;
    color: #9BA9BD;
}

.v-btn ::v-deep .v-input--selection-controls__input {
    margin: 0;
}

.v-file-input .v-input__control {
    display: none;
}

.v-file-input .v-label {
    width: 100%;
    text-align: center;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-placeholder {
    font-size: 40px;
    color: #c9c9c9;
}

.uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.uploading-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #8e84c0;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
}

.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.title-question {
    font-size: 18px !important;
    color: #4a5568;
    font-weight: 800;
    margin-top: 16px !important;
}

.buttons-card {
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.card-actions {
    float: right;
}

.list-events .v-list-item__title {
    color: #4a5568 !important;
    font-size: 14px !important;
}

.list-events .v-list-item__subtitle {
    color: #828da1 !important;
    font-size: 10px !important;
}

.button-delete {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: absolute !important;
    left: auto;
    top: 50%;
    right: 0%;
    width: fit-content !important;
    margin-top: 0% !important;
    transform: translate(0%, -50%);

}

@media (max-width: 600px) {
    .card-actions {
        padding-bottom: 12px !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 0px !important;
        margin-left: 0px !important;
        float: none;
    }

    .buttons-card {
        padding-left: 16px !important;
        padding-right: 16px !important;
        margin-bottom: 10px;
        /* Añade un margen inferior para separarlos */
        text-align: center;
    }

    .title-question {
        text-align: center;
        /* Centra el texto de 'Recortar Imagen' */
        width: 100%;
    }
}

.nextSchedule {
    font-size: 10px;
    padding: 2px 5px;
    background-color: rgb(3, 218, 3);
    color: white;
    border-radius: 6px;
}

.timer {
    width: 100%;
    border-radius: 5px;
}


.icon {
    position: absolute !important;
    top: 50%;
    left: auto;
    right: 3%;
    transform: translate(-50%, -50%);
}
</style>


<style lang="scss">
.vue__time-picker input.display-time {
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 12px;
    font-weight: 500;
    height: 40px !important;
}

.char {
    display: none;
}

.active {
    background: #8e84c0 !important;
    border-radius: 5px;
}

.select-list {
    border-radius: 8px !important;
}

.vue__time-picker .dropdown {
    box-shadow: 0 1px 6px rgba(0, 0, 0, .3);
    border-radius: 8px;
}

.alert {
    margin: 0 !important;
    padding: 0 !important;
}

.v-dialog {
    width: fit-content !important;
}

.scope-margin .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0% !important;
}

.relative {
    position: relative;
}
</style>