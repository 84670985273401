<template>
    <v-col class="px-0 py-0 mb-5">
        <div :style="fileInputStyle" @click="triggerFileInput">
            <v-img
                v-if="previewUrl && !uploading"
                :src="previewUrl"
                max-width="100%"
                max-height="100%"
                class="mt-0 image-preview"
            ></v-img>
            <div v-else-if="uploading" class="uploading-container">
                <v-progress-linear
                    :value="uploadProgress"
                    color="primary"
                    height="15"
					striped
                    class="progress-bar mb-4"
                ></v-progress-linear>
                <div class="uploading-text">{{ $t("imageUpload.Uploadingfile") }}</div>
            </div>
            <v-icon v-else class="icon-placeholder">
                mdi-image-multiple-outline
            </v-icon>
        </div>
        <v-file-input
            ref="fileInput"
            v-model="files"
            accept="image/jpeg,image/png,image/jpg,image/gif,image/bmp,image/webp,image/tiff,image/x-icon"
            show-size
            :label="$t('imageUpload.Uploadfile')"
            prepend-icon="mdi-image-edit-outline"
            @change="previewImage"
            style="display: none"
            multiple
        ></v-file-input>
        <p class="text-info p-0 mt-0">{{ $t('harmony.info-text-album') }}</p>
    </v-col>
</template>

<script>
import axios from '@/axios.js';


export default {
    name: 'ImageUploadGallery',
    props: {
        selected: {
            type: String,
            required: false,
            default: ''
        },
        uploadEndpoint: {
            type: String,
            required: true,
        },
        cardSelected: {
            type: String,
            required: false,
            default: ''
        },
        siteId: {
            type: Number,
            required: false,
            default: 0
        },
        previewUrl: {
            type: String,
            required: false,
            default: ''
        },
        updatedGallery: {
            type: Boolean,
            required: true,
            default: false
        },
        galleryId: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            files: null,
            imagePreview: null,
            uploading: false,
            uploadProgress: 0,
            imgSrc: null,
            loading: false
        };
    },

    computed: {
        fileInputStyle() {
            return {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                border: '2px solid #C9C9C9',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
            };
        },
        computedMaxWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '90%' : '900px';
    }
    },

    created() {
        this.setLanguage()
    },

    methods: {
        triggerFileInput() {
            this.$refs.fileInput.$el.querySelector('input').click();
        },

        previewImage(files) {
            const MAX_SIZE = 30 * 1024 * 1024; // 30MB

            const ALLOWED_TYPES = [
                'image/jpeg', 'image/png', 'image/jpg', 'image/gif',
                'image/bmp', 'image/webp', 'image/tiff', 'image/x-icon'
            ];

            const validFiles = [];

            for (let file of files) {
                if (!ALLOWED_TYPES.includes(file.type)) {
                    console.log('Tipo de archivo no permitido:', file.type);
                    this.$vs.notify({
                        title: this.$t('imageUpload.typeError-title'),
                        text: this.$t('imageUpload.typeError-text'),
                        color: 'warning',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                    continue;
                }

                if (file.size > MAX_SIZE) {
                    this.$vs.notify({
                        title: this.$t('imageUpload.sizeError-title'),
                        text: this.$t('imageUpload.sizeError-text', { maxSize: '30MB' }),
                        color: 'warning',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                    continue;
                }
                validFiles.push(file);

            }
            if (validFiles.length > 0) {
                this.uploadImages(validFiles);
            }
        },

        async uploadImages(files) {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('files[]', file);
            });
            formData.append('card', this.cardSelected);
            formData.append('site_id', this.siteId);
            formData.append('updated_gallery', JSON.stringify(this.updatedGallery));
            if(this.updatedGallery){
                formData.append('gallery_id', this.galleryId);
            }
            this.uploading = true;
            this.uploadProgress = 0;
            try {
                const response = await axios.post(this.uploadEndpoint, formData, {

                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                });

                this.uploading = false;
                this.uploadProgress = 0;
                this.files = null;

                if (response && this.selected == 'logo') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadLogo-title'),
                        text: this.$t('imageUpload.uploadLogo-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else if (response && this.selected == 'card') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadCard-title'),
                        text: this.$t('imageUpload.uploadCard-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else if (response && this.selected == 'background') {
                    this.$vs.notify({
                        title: this.$t('imageUpload.uploadBackground-title'),
                        text: this.$t('imageUpload.uploadBackground-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
                this.$emit('image-uploaded-gallery');
                this.$emit('images-uploaded');
            } catch (error) {
                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.files = null;
                this.$vs.notify({
                    title: this.$t('imageUpload.uploadError-title'),
                    text: this.$t('imageUpload.uploadError-text'),
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error('Upload Error:', error);
            }
        },

        setLanguage(){
            const storedLocale = localStorage.getItem('locale');
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = 'es'
            }
        },
    },
};
</script>

<style scoped>
.v-file-input .v-input__control {
    display: none;
}
.v-file-input .v-label {
    width: 100%;
    text-align: center;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-placeholder {
    font-size: 40px;
    color: #C9C9C9;
}

.uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.uploading-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #8e84c0;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
}

.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}
.title-question {
    font-size: 18px !important;
    color: #4A5568;
    font-weight: 800;
    margin-top: 16px !important;
}
.buttons-card {
    padding-left: 32px !important;
    padding-right: 32px !important;
}
.card-actions {
float: right;
}

@media (max-width: 600px) {
    .card-actions {
        padding-bottom: 12px !important;
        display: flex;
        justify-content: space-around; 
        align-items: center;
        margin-right: 0px !important;
        margin-left: 0px !important;
        float: none;
    }

    .buttons-card {
        padding-left: 16px !important;
        padding-right: 16px !important;
        margin-bottom: 10px; /* Añade un margen inferior para separarlos */
          text-align: center;
    }

    .title-question {
        text-align: center; /* Centra el texto de 'Recortar Imagen' */
        width: 100%;
    }
}

.text-info {		
    font-size: 12px;
    color: #9BA9BD;
    text-align: left;
    margin-bottom: 0px !important;
    margin-top: 2px !important;
}

</style>


<style lang="scss">

.alert {
    margin: 0 !important;
    padding: 0 !important;
}

.cropper {
    max-width: 90%;
}

</style>
