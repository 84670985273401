<template>
    <v-col class="px-0 py-0">
        <div :style="fileInputStyle" @click="triggerFileInput">
            <video
                v-if="previewUrl && !uploading"
                :src="previewUrl"
                max-width="100%"
                max-height="100%"
                class="mt-0 image-preview"
                autoplay
                muted
                loop
                playsinline
                preload="auto"
                controls="false"
            ></video>
            <div v-else-if="uploading" class="uploading-container">
                <v-progress-linear
                    :value="uploadProgress"
                    color="primary"
                    height="15"
					striped
                    class="progress-bar mb-4"
                ></v-progress-linear>
                <div class="uploading-text">{{ $t("videoUpload.Uploadingfile") }}</div>
            </div>
            <v-icon v-else class="icon-placeholder">
                mdi-video-plus
            </v-icon>
        </div>
        <v-file-input
            ref="fileInput"
            v-model="file"
            accept="video/mp4,video/x-msvideo,video/x-matroska,video/quicktime,video/x-flv,video/webm"
            show-size
            :label="$t('videoUpload.Uploadfile')"
            @change="previewVideo"
            style="display: none"
        ></v-file-input>
    <p class="text-info p-0 mt-0">{{ $t('harmony.info-text-video') }}</p>
    </v-col>

</template>

<script>
import axios from '@/axios.js';

export default {
    name: 'VideoUpload',
    props: {
        selected: {
            type: String,
            required: false,
            default: ''
        },
        uploadEndpoint: {
            type: String,
            required: true,
        },
        cardSelected: {
            type: String,
            required: false,
            default: ''
        },
        siteId: {
            type: Number,
            required: false,
            default: 0
        },
        previewUrl: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            file: null,
            videoPreview: null,
            uploading: false,
            uploadProgress: 0,
        };
    },

    computed: {
        fileInputStyle() {
            return {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                border: '2px solid #C9C9C9',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
            };
        },
    },

    created() {
        this.setLanguage()
    },

    methods: {
        triggerFileInput() {
            this.$refs.fileInput.$el.querySelector('input').click();
        },

        previewVideo(file) {
            const MAX_SIZE = 200 * 1024 * 1024; // 200MB
            const ALLOWED_TYPES = ['video/mp4', 'video/x-msvideo', 'video/x-matroska', 'video/quicktime', 'video/x-flv', 'video/webm'];

            if (file.size > MAX_SIZE) {
                this.$vs.notify({
                    title: this.$t('videoUpload.sizeError-title'),
                    text: this.$t('videoUpload.sizeError-text', { maxSize: '200MB' }),
                    color: 'warning',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                return;
            }

            if (!ALLOWED_TYPES.includes(file.type)) {
                this.$vs.notify({
                    title: this.$t('videoUpload.typeError-title'),
                    text: this.$t('videoUpload.typeError-text'),
                    color: 'warning',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                this.videoPreview = e.target.result;
            };

            reader.readAsDataURL(file);
            this.uploadVideo(file);
        },

        async uploadVideo(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('card', this.cardSelected);
            formData.append('site_id', this.siteId);

            this.uploading = true;
            this.uploadProgress = 0;

            try {
                const response = await axios.post(this.uploadEndpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                });

                this.uploading = false;
                this.uploadProgress = 0;
                this.videoPreview = null;
                this.file = null;

              if (response && this.selected == 'card') {
                    this.$vs.notify({
                        title: this.$t('videoUpload.UploadVideo-title'),
                        text: this.$t('videoUpload.UploadVideo-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
                this.$emit('video-uploaded');
            } catch (error) {
                this.uploading = false;
                this.uploadProgress = 0;
                this.videoPreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t('videoUpload.uploadError-title'),
                    text: this.$t('videoUpload.uploadError-text'),
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error('Upload Error:', error);
            }
        },

        setLanguage(){
            const storedLocale = localStorage.getItem('locale');
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = 'es'
            }
        },
    },
};
</script>

<style scoped>

.text-info {
    font-size: 12px;
    color: #9BA9BD;
    text-align: left;
    margin-bottom: 0px !important;
    margin-top: 2px !important;
}

.image-preview::-webkit-media-controls {
  display: none !important;
}

.image-preview::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.image-preview::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

.image-preview::-webkit-media-controls-volume-slider {
  display: none !important;
}


.v-file-input .v-input__control {
    display: none;
}
.v-file-input .v-label {
    width: 100%;
    text-align: center;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-placeholder {
    font-size: 40px;
    color: #C9C9C9;
}

.uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.uploading-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #8e84c0;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
}

.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}
</style>
